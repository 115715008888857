import React from "react";
interface TrashIconProps {
  size?: string;
}
const CodeIcon: React.FC<TrashIconProps> = ({ size = "25" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    width={size}
    height={size}
    stroke="currentColor"
    fill="none"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
    />
  </svg>
);

export default CodeIcon;
