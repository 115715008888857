import React, { useEffect, useState } from "react";
import { useInstanceContext } from "../context";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Tab,
  Tabs,
  Image,
  CardBody,
} from "@nextui-org/react";
import SubscriptionsComponent from "../components/instances/subscriptionsComponent";
import ArrowLeftIcon from "../assets/icons/arrowLeftIcon";
import { useNavigate } from "react-router-dom";
import InstanceInfoComponent from "../components/instances/instanceInfoComponent";
import { Post } from "../interfaces";
import { usePostStore } from "../stores";
import ShareIcon from "../assets/icons/shareIcon";
import formatDate from "../utils/formatedDate";
import FireIcon from "../assets/icons/fireIcon";
import ChechCircleIcon from "../assets/icons/checkCircleIcon";
import LikeIcon from "../assets/icons/likeIcon";
interface FacebookData {
  id: string;
  name: string;
  about?: string;
  category: string;
  link: string;
  phone: string | null;
  username: string | null;
  emails: string[] | null;
  picture?: {
    data: {
      height: number;
      width: number;
      is_silhouette: boolean;
      url: string;
    };
  };
}
const PostComponent = ({ post }: { post: Post }) => {
  return (
    <Card className="w-1/2 my-5 p-0">
      <CardHeader className="flex p-0 flex-col items-start">
        <Image
          src={post.fullPicture}
          alt="postImage"
          width="100%"
          className="w-full p-0 m-0 rounded-t-lg rounded-b-none"
          content="fill"
        />
      </CardHeader>
      <CardBody>
        <section className="py-3 m-0">
          <p className="text-lg subpixel-antialiased mb-2">{post.message}</p>
          <section className="flex justify-between">
            <p className="font-light text-xs text-cyan-700">
              Creado en: {formatDate(new Date(post.createdTime).getTime())}
            </p>
            <p className="font-light text-xs text-cyan-700">
              Post ID: {post.id}
            </p>
          </section>
        </section>
      </CardBody>
      <CardFooter className="flex justify-between">
        <section
          className={`flex gap-1 justify-center align-center mx-3 ${
            post.isPopular ? "text-red-900" : "text-gray-600"
          }`}
        >
          <FireIcon /> {post.isPopular ? "Es Popular" : "Aun no es popular"}
        </section>
        <section
          className={`flex gap-1 justify-center align-center mx-3 ${
            post.isPublished ? "text-primary" : "text-gray-600"
          }`}
        >
          <ChechCircleIcon /> {post.isPublished ? "Publicado" : "No Publicado"}
        </section>
        <section
          className={`flex gap-1 justify-center align-center mx-3 ${
            post.likes > 0 ? "text-primary" : "text-gray-600"
          }`}
        >
          <LikeIcon /> {post.likes}
        </section>
        <section
          className={`flex gap-1 justify-center align-center mx-3 ${
            post.shares > 0 ? "text-primary" : "text-gray-600"
          }`}
        >
          <ShareIcon /> {post.shares}
        </section>
      </CardFooter>
    </Card>
  );
};
const InstancePage: React.FC = () => {
  const { selectedInstance } = useInstanceContext();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<Post[]>();
  const [facebookData, setFacebookData] = useState<FacebookData | null>(null);
  const { getAllPosts } = usePostStore();
  const getPostsAsync = async (id: string) => {
    const response = await getAllPosts(id);
    console.log(response);
    setPosts(response);
  };
  useEffect(() => {
    if (selectedInstance?.meta?.type === "facebook") {
      getPostsAsync(selectedInstance?.meta?.providerId!);
      const fetchData = async () => {
        try {
          const response = await fetch(
            `https://graph.facebook.com/v22.0/me?fields=id,name,about,bio,category,description,emails,general_info,link,members,location,phone,products,username,picture.width(800).height(800)&access_token=${selectedInstance?.meta?.token}`
          );
          const data = await response.json();
          setFacebookData(data);
          console.log(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      if (facebookData === null) {
        fetchData();
      }
    }
  }, [selectedInstance]);
  return (
    <div className="container mx-auto p-4">
      <Button
        startContent={<ArrowLeftIcon />}
        variant="light"
        onClick={() => navigate("/welcome")}
        className="mb-4"
      >
        Volver
      </Button>
      <section className="mx-4 shadow-lg rounded-lg p-4 bg-gray-100 text-left flex flex-col items-start justify-between w-full">
        <Tabs aria-label="Options" color="primary" className="w-full">
          {selectedInstance?.meta?.type === "facebook" && (
            <Tab key="info" title="Informacion" className="w-full">
              <InstanceInfoComponent facebookData={facebookData} />
              <p className="text-3xl font-bold my-5">Feeds</p>
              <div className="flex flex-wrap gap-4 items-center justify-center">
                {posts ? (
                  posts.map((post) => (
                    <PostComponent post={post} key={post.id} />
                  ))
                ) : (
                  <p>Sin Posts registrados</p>
                )}
              </div>
            </Tab>
          )}
          <Tab key="config" title="Configuración" className="w-full">
            <SubscriptionsComponent />
          </Tab>
        </Tabs>
      </section>
    </div>
  );
};

export default InstancePage;
