import React, { useEffect, useState } from "react";
import { AddInstanceResponse, subscriptionName } from "../../interfaces";
import {
  Button,
  ButtonGroup,
  Code,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react"; // Importar la tabla de NestUI
import TrashIcon from "../../assets/icons/trashIcon";
import { useInstanceContext } from "../../context";
import { set } from "date-fns";
import CodeIcon from "../../assets/icons/codeIcon";

interface Subscription {
  name: subscriptionName;
  url: string;
  taskId: number;
}

const SubscriptionsComponent: React.FC = () => {
  const {
    selectedInstance,
    setSelectedInstance,
    getUserInstances,
    updateInstance,
  } = useInstanceContext();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>(
    selectedInstance?.meta?.subscriptions || []
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [newSubscription, setNewSubscription] = useState<Subscription>({
    name: subscriptionName.feed,
    url: "",
    taskId: 0,
  });
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [imcData, setImcData] = useState({
    url: selectedInstance?.cyt?.IMC?.url ?? "",
    port: selectedInstance?.cyt?.IMC?.port ?? 0,
    token: selectedInstance?.cyt?.IMC?.token ?? "",
  });
  const addSubscription = (subscription: Subscription) => {
    setSubscriptions([...subscriptions, subscription]);
  };
  const removeSubscription = (name: string) => {
    setSubscriptions(
      subscriptions.filter((subscription) => subscription.name !== name)
    );
  };
  const getCYTJSon = () => {
    return {
      Id: selectedInstance?.cyt?.id,
      Meta: {
        PageId: selectedInstance?.meta?.providerId,
        AccessToken: selectedInstance?.meta?.token,
        VerifyToken: selectedInstance?.meta?.type,
        ApiUrl: "https://graph.facebook.com/v20.0/",
        Type: selectedInstance?.meta?.type,
      },
      ChatCyt: {
        Url: `${imcData.url ?? "[Dominio]"}:${
          imcData.port ?? "[Puerto]"
        }/FacebookMessenger/ManageMessenger`,
        Token: imcData.token,
      },
    };
  };
  const saveSubscriptions = () => {
    setSelectedInstance({
      ...selectedInstance!,
      meta: {
        ...selectedInstance?.meta,
        subscriptions,
        token: selectedInstance?.meta?.token ?? "",
        providerId: selectedInstance?.meta?.providerId ?? "",
        type: selectedInstance?.meta?.type ?? "",
      },
    });
  };
  useEffect(() => {
    saveSubscriptions();
  }, [subscriptions]);

  return (
    <div className="w-full mx-5">
      <section>
        <h2 className="text-2xl font-bold">Suscripciones</h2>
        <div className="flex flex-row gap-4 my-4 items-center bg-cyan-500 p-4 rounded-lg">
          <Dropdown>
            <DropdownTrigger>
              <Button
                className="capitalize bg-white text-cyan-500"
                variant="flat"
                size="lg"
              >
                {newSubscription.name}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              disallowEmptySelection
              selectedKeys={selectedKeys as any}
              selectionMode="single"
              variant="flat"
              onSelectionChange={(keys) => {
                setNewSubscription({
                  ...newSubscription,
                  name: Array.from(keys)[0] as subscriptionName,
                });
              }}
            >
              <DropdownItem key="feed">{subscriptionName.feed}</DropdownItem>
              <DropdownItem key="messenger">
                {subscriptionName.messenger}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Input
            label="URL"
            className="w-1/2"
            size="sm"
            onChange={(e) =>
              setNewSubscription({ ...newSubscription, url: e.target.value })
            }
          />
          <Input
            label="Id de Tarea"
            type="number"
            className="w-1/4"
            size="sm"
            onChange={(e) =>
              setNewSubscription({
                ...newSubscription,
                taskId: Number(e.target.value),
              })
            }
          />

          <Button
            onClick={() => addSubscription(newSubscription)}
            size="lg"
            className="bg-gray-900 text-white"
          >
            Agregar Suscripción
          </Button>
        </div>
      </section>
      <Table aria-label="Example table with dynamic content" removeWrapper>
        <TableHeader>
          <TableColumn>Nombre</TableColumn>
          <TableColumn>URL</TableColumn>
          <TableColumn>Id de Tarea</TableColumn>
          <TableColumn>Acciones</TableColumn>
        </TableHeader>
        <TableBody>
          {subscriptions.map((subscription, index) => (
            <TableRow key={subscription.name}>
              <TableCell>{subscription.name}</TableCell>
              <TableCell>{subscription.url}</TableCell>
              <TableCell>{subscription.taskId}</TableCell>
              <TableCell>
                <ButtonGroup>
                  <Button onClick={onOpen} variant="light">
                    <Tooltip content="Ver Codigo">
                      <span className="text-secondary cursor-pointer active:opacity-50">
                        <CodeIcon />
                      </span>
                    </Tooltip>
                  </Button>
                  <Button
                    onClick={() => removeSubscription(subscription.name)}
                    variant="light"
                  >
                    <Tooltip content="Eliminar Suscripción">
                      <span className="text-danger cursor-pointer active:opacity-50">
                        <TrashIcon />
                      </span>
                    </Tooltip>
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="5xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                JSON de la suscripción
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-row gap-4 mt-4">
                  <Input
                    label="URL de IMC"
                    value={imcData.url}
                    onChange={(e) =>
                      setImcData({ ...imcData, url: e.target.value })
                    }
                  />
                  <Input
                    label="Puerto de IMC"
                    value={imcData.port?.toString()}
                    type="number"
                    onChange={(e) =>
                      setImcData({ ...imcData, port: Number(e.target.value) })
                    }
                  />
                  <Input
                    label="Token de IMC"
                    value={imcData.token}
                    onChange={(e) =>
                      setImcData({ ...imcData, token: e.target.value })
                    }
                  />
                </div>
                <section>
                  <Code
                    style={{
                      overflowX: "scroll",
                      whiteSpace: "nowrap",
                      wordBreak: "break-all",
                      width: "100%",
                    }}
                  >
                    <pre>{JSON.stringify(getCYTJSon(), null, 2)}</pre>
                  </Code>
                </section>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  variant="flat"
                  onPress={() =>
                    navigator.clipboard.writeText(
                      JSON.stringify(getCYTJSon(), null, 2)
                    )
                  }
                >
                  Copiar
                </Button>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cerrar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Button
        className="bg-purple-900 mt-5 hover:bg-purple-600 text-white"
        onClick={async () => {
          let response = await updateInstance(
            {
              cyt: selectedInstance?.cyt,
              meta: selectedInstance?.meta,
              name: selectedInstance?.name ?? "-",
              owner: selectedInstance?.owner ?? "-",
            },
            selectedInstance!._id
          );
          if (response) {
            getUserInstances(selectedInstance!.owner);
          }
        }}
      >
        Guardar
      </Button>
    </div>
  );
};

export default SubscriptionsComponent;
