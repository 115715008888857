import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Link,
  Spinner,
} from "@nextui-org/react";

interface InstanceInfoProps {
  facebookData: FacebookData | null;
}

interface FacebookData {
  id: string;
  name: string;
  about?: string;
  category: string;
  link: string;
  phone: string | null;
  username: string | null;
  emails: string[] | null;
  picture?: {
    data: {
      height: number;
      width: number;
      is_silhouette: boolean;
      url: string;
    };
  };
}

const InstanceInfoComponent: React.FC<InstanceInfoProps> = (props) => {
  const { facebookData } = props;
  return (
    <Card>
      {facebookData ? (
        <>
          <CardHeader>
            <Avatar
              radius="lg"
              src={
                facebookData.picture?.data?.url ||
                "URL_POR_DEFECTO_SI_NO_HAY_IMAGEN"
              }
              alt={facebookData.name}
            />
            <div className="ml-2">
              <h4 className="font-bold text-large">{facebookData.name}</h4>
              <p className="text-tiny text-uppercase font-light">
                {facebookData.category}
              </p>
            </div>
          </CardHeader>
          <CardBody>
            <p className="text-lg font-bold">Informacion</p>{" "}
            <p className="font-light text-md">
              {facebookData.about ?? "No description"}
            </p>
            {facebookData.username && (
              <>
                <p className="my-1 text-lg font-bold">Username:</p>
                <p className="font-light text-md">{facebookData.username}</p>
              </>
            )}
            {facebookData.emails && facebookData.emails?.length > 0 && (
              <>
                <p className="my-1 text-lg font-bold">Email Principal:</p>
                <p className="font-light text-md">{facebookData.emails[0]}</p>
              </>
            )}
            {facebookData.phone && (
              <>
                <p className="my-1 text-lg font-bold">Telefono:</p>
                <p className="font-light text-md">{facebookData.phone}</p>
              </>
            )}
            <Button
              showAnchorIcon
              as={Link}
              color="primary"
              href={facebookData.link}
              variant="solid"
              className="mt-4"
            >
              Ver en Facebook
            </Button>
          </CardBody>
        </>
      ) : (
        <Spinner color="secondary" />
      )}
    </Card>
  );
};

export default InstanceInfoComponent;
