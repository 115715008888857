import { ChatPage, LoginPage, WelcomePage } from "../pages";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { AuthProvider, ChatProvider, InstanceProvider } from "../context";
import PostPage from "../pages/posts";
import InstancePage from "../pages/instance";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/chat"
        element={
          <ChatProvider>
            <ChatPage />
          </ChatProvider>
        }
      />
      <Route path="/selectedInstance" element={<InstancePage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/posts/:instanceId" element={<PostPage />} />
    </Route>
  )
);
export default function IntegraRouter() {
  return (
    <AuthProvider>
      <InstanceProvider>
        <RouterProvider router={router} />
      </InstanceProvider>
    </AuthProvider>
  );
}
