//#region Add Instance
export enum subscriptionName {
  messenger = "messenger",
  feed = "feed",
}
export interface AddInstancePayload {
  name: string;
  cyt?: {
    id?: number;
    url?: string;
    IMC?: {
      url?: string;
      port?: number;
      token?: string;
    };
  };
  meta?: {
    token: string;
    providerId: string;
    type: string;
    profilePicture?: string;
    subscriptions?: {
      name: subscriptionName;
      url: string;
      taskId: number;
    }[];
  };
  owner: string;
}
export interface AddInstanceResponse extends AddInstancePayload {
  _id: string;
}
