import { Avatar, Button } from "@nextui-org/react";
import { AddInstanceResponse } from "../../interfaces";
import { useAuthContext, useInstanceContext } from "../../context";
import FacebookIcon from "../../assets/icons/facebookIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import GearIcon from "../../assets/icons/gearIcon";
import MessageIcon from "../../assets/icons/messageIcon";
import { useNavigate } from "react-router-dom";
import ChartIcon from "../../assets/icons/chartIcon";

export const InstancesList = () => {
  const { user } = useAuthContext();
  const { instances, setSelectedInstance } = useInstanceContext();
  const navigate = useNavigate();
  return instances.length > 0 ? (
    <div className="flex flex-col items-center justify-center mt-4 w-full">
      <div className="text-lg text-white">Instancias encontradas:</div>
      <div className="flex flex-col items-center justify-center mt-4 gap-2 w-full">
        {instances.map((instance: AddInstanceResponse) => (
          <div
            key={instance._id}
            className="shadow-lg rounded-lg p-4 bg-white text-center flex items-center justify-between w-full"
          >
            <section className="flex items-center justify-center">
              <Avatar
                size="lg"
                src={
                  instance.meta?.profilePicture ??
                  "https://i.pravatar.cc/150?u=a042581f4e29026024d"
                }
              />
              {instance.meta?.type === "facebook" ? (
                <FacebookIcon width="50" height="50" color="#4267B2" />
              ) : (
                <InstagramIcon width="50" height="50" color="#F56040" />
              )}
              <section className="flex flex-col items-start justify-center ml-2">
                <span className="text-primary text-lg">{instance.name}</span>
                <span className="text-secondary text-xs font-light">
                  {instance.meta!.providerId}
                </span>
              </section>
            </section>
            {user?.role === "cyt" && (
              <div className="flex items-center justify-center space-x-2 text-primary">
                {instance.meta?.type === "facebook" && (
                  <Button
                    onClick={() => {
                      navigate(`/posts/${instance.meta?.providerId}`);
                    }}
                    className="ml-2 px-1 py-1 text-primary bg-transparent hover:bg-gray-300"
                  >
                    <ChartIcon />
                  </Button>
                )}
                <Button
                  onClick={() => {
                    navigate(`/chat`);
                  }}
                  className="ml-2 px-1 py-1 text-white bg-transparent hover:bg-gray-300"
                >
                  <MessageIcon width="20" height="20" color="#4267B2" />
                </Button>
                <Button
                  onClick={() => {
                    setSelectedInstance(instance);
                    navigate(`/selectedInstance`);
                  }}
                  className="ml-2 px-1 py-1 text-white bg-transparent hover:bg-gray-300"
                >
                  <GearIcon width="20" height="20" color="#4267B2" />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};
